.material-card {
    font-family: var(--open-sans);
}

.material-card .title {
    font-size: 3rem !important;
}

.material-card .subtitle
{
    font-size: 1.8rem !important;
    font-style: italic;
    letter-spacing: .07rem;
    line-height: 2.1rem;
}

