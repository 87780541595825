/* Customize the label (the container) */
.calc-container {
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 2.2vh;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    font-family: var(--open-sans);
    text-align: center;
}

/* Hide the browser's default radio button */
.calc-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.calc-container input:checked~p {
    color: var(--main-color);
}

.calc-container p{
    transition: all .2s;
}