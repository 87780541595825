.contacts hr {
    width: 90vw;
    border-width: 2.5px !important;
}

.contacts {
    font-family: var(--open-sans);
    color: white;
}

.contacts h3 {
    margin: 3vh 5vw 0 5vw;
    text-align: center;
    font-size: max(16px, 3.5vmin);
    font-weight: 700;
}

.contacts .holder {
    padding: 2vmin 5vw;
    display: flex;
    flex-direction: row-reverse;
    gap: 5%;
    height: max-content;
}

.contacts .form {
    padding: 5vmax;
    border: 2px solid var(--main-color);
    width: 35%;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    gap: 3vh;
}

.contacts .map-holder {
    width: 50%;
    flex-grow: 1;
}

.contacts .social-group {
    display: flex;
    width: fit-content;
    margin: auto;
}

.contacts .social-group>* {
    width: 5vw;
    height: 5vw;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 3px transparent;
    background-image: var(--bg-url), linear-gradient(var(--main-color) 0%,
            hsl(from var(--main-color) h s calc(l * .88)) 16.5%,
            hsl(from var(--main-color) h s calc(l * .78)) 33%,
            hsl(from var(--main-color) h s calc(l * .62)) 55%,
            hsl(from var(--main-color) h s calc(l * .56)) 79.5%,
            hsl(from var(--main-color) h s calc(l * .32)) 100%);
    background-origin: border-box;
    background-clip: padding-box, border-box;
    border-right-width: 0;
}

.contacts .social-group>*:last-child {
    border-right-width: 3px;
    border-top-right-radius: 2vmin;
    border-bottom-right-radius: 2vmin;
}

.contacts .social-group>*:first-child {
    border-top-left-radius: 2vmin;
    border-bottom-left-radius: 2vmin;
}

.contacts .social-group>*>*,
.small-social {
    width: 60%;
    height: 60%;
    background-image: none;
    background-color: var(--main-color);
    mask-repeat: no-repeat;
    mask-size: contain;
    mask-position: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.contacts .telegram {
    -webkit-mask-image: url(../../../images/socials/TelegramOrange.svg);
    mask-image: url(../../../images/socials/TelegramOrange.svg);
}

.contacts .whatsapp {
    -webkit-mask-image: url(../../../images/socials/WhatsAppOrange.svg);
    mask-image: url(../../../images/socials/WhatsAppOrange.svg);
}

.contacts .facebook {
    -webkit-mask-image: url(../../../images/socials/Facebook.svg);
    mask-image: url(../../../images/socials/Facebook.svg);
}

.contacts .viber {
    -webkit-mask-image: url(../../../images/socials/ViberOrange.svg);
    mask-image: url(../../../images/socials/ViberOrange.svg);
}

.contacts input,
.contacts button {
    all: unset;
    color: white;
    font-size: max(12px, 2vmin);
    padding: 1%;
}

.contacts button {
    text-align: center;
}

.contacts button {
    background-image: var(--main-dark-gradient);
}

.contacts input[type=tel] {
    border: 3px solid transparent;
    background-color: transparent;
    box-shadow: 2px 2px 5px 0 #0000007f;
    background-image: var(--bg-url), var(--main-dark-gradient);
    background-origin: border-box;
    background-clip: padding-box, border-box;
}

.contacts .title {
    font-size: max(18px, 6vmin);
}

.contacts .phones-holder {
    position: relative;
    font-size: large;
    margin-bottom: 3%;
    padding-top: 3%;
    padding-left: 3%;
    padding-right: 3%;
}

.contacts .phones-holder>div {
    position: absolute;
    height: 100%;
    width: 100%;
    margin-left: -3%;
    margin-top: -3%;
}

.contacts .line-hr,
.contacts .line-vr {
    position: absolute;
}

.contacts .line-hr {
    border-top: 2px solid var(--main-color);
}

.contacts .line-vr {
    border-left: 2px solid var(--main-color);
}

.contacts .line1 {
    width: 10%;
    left: 5%;
}

.contacts .line2 {
    height: 60%;
    top: 15%;
}

.contacts .line3 {
    width: 10%;
    right: 3vw;
    bottom: 0;
}

.contacts .line4 {
    height: 40%;
    right: 0;
    bottom: 11vw;
}

.contacts p {
    font-family: var(--open-sans);
    font-size: max(13px, 4vmin);
    margin: 0;
    line-height: 1.2;
}

.contacts p:last-child {
    line-height: 2.2;
}

.socials {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    gap: 1vw;
    translate: 1.5vw 1.5vw;
}

.small-social {
    width: 3vw;
    height: 3vw;
    background-color: var(--main-color);
}

@media (orientation: landscape) {
    .contacts .phones {
        display: none;
    }
}

@media (orientation: portrait) {

    .contacts input,
    .contacts button {
        font-size: max(12px, 3.5vmin);
    }

    .map-holder {
        height: 30vh;
    }

    .contacts .social-group>*:last-child {
        border-top-right-radius: 3.5vmin;
        border-bottom-right-radius: 3.5vmin;
    }

    .contacts .social-group>*:first-child {
        border-top-left-radius: 3.5vmin;
        border-bottom-left-radius: 3.5vmin;
    }

    .contacts .social-group>* {
        width: 13vw;
        height: 13vw;
    }

    .contacts .form,
    .contacts .map-holder {
        width: 100%;
    }

    .contacts h3,
    .contacts>hr {
        display: none;
    }

    .contacts .holder {
        flex-direction: column;
    }
}