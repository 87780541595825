.calc-spacer {
    height: 5vh;
}

.calc-placeholder {
    transition: all .2s;
    object-fit: cover;
    border: solid 3px var(--main-color);
    width: 40%;
}

.calc-portrait-only {
    display: none;
}

.calc p {
    margin: 0;
}

.calc form>p {
    font-size: max(2.5vh, 14px);
    text-align: center;
}

.calc {
    display: flex;
    width: 84vw;
    margin: auto;
}

.calc #facture {
    display: none;
}

.facture-radio-holder {
    align-self: stretch;
    justify-content: space-around;
    display: flex;
}

.messanger-radio-holder {
    display: flex;
}

.calc form {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    margin: auto 0;
    border: solid 3px var(--main-color);
    border-left: 0;
    padding: 3vw;
    gap: 1vh;
    flex-shrink: 1;
}

.calc-input {
    display: flex;
    padding: .5vh;
    gap: 1vw;
    align-items: center;
}

.calc-input>p.large {
    font-size: 2.5vh;
    text-wrap: wrap;
}

.calc-input>p.small {
    display: none;
}

.calc-input>*:last-child {
    width: 100%;
}

.area-input-holder {
    display: flex;
    justify-content: stretch;
    align-items: center;
    font-size: 2.2vh;
    color: white;
}

.area-input-ending {
    border: solid 3px var(--main-color);
    padding: .4vmax;
    border-left: 0;
    border-top-right-radius: 1vh;
    border-bottom-right-radius: 1vh;
    margin-left: -1px;
    background-color: #4242427a;
}

.area-input {
    all: unset;
    flex-grow: 1;
    background-color: transparent;
    font-weight: 100;
    border: solid 3px var(--main-color);
    min-width: 15vw;
    border-top-left-radius: 1vh;
    border-bottom-left-radius: 1vh;
    color: white;
    padding: .4vmax;
}

.calc-input.phone {
    gap: 5%;
    align-self: stretch;
}

.calc-input input[type=tel],
.calc-input input[type=submit],
.calc-input button[type=submit] {
    all: unset;
    transition: all .2s;
    border: solid 3px var(--main-color);
    min-width: 0;
    border-radius: 1vh;
    color: white;
    padding: .4vmax;
    font-size: min(3.8vw, 2.2vh);
    width: 50%;
}

.calc-input input[type=tel] {
    background-color: transparent;
    font-weight: 100;
}

.calc-input input[type=submit],
.calc-input button[type=submit] {
    cursor: pointer;
    background-color: var(--main-color);
    font-weight: 500;
    text-align: center;
    text-wrap: nowrap;
}

.calc-input input[type=submit]:hover,
.calc-input button[type=submit]:hover {
    background-color: hsl(from var(--main-color) h s calc(l * 1.2));
    border-color: hsl(from var(--main-color) h s calc(l * 1.2));
}

.calc-input input[type=submit]:active,
.calc-input button[type=submit]:active {
    background-color: hsl(from var(--main-color) h s calc(l * .8));
    border-color: hsl(from var(--main-color) h calc(s * .8) calc(l * .8));
}


.area-input::selection {
    background-color: var(--main-color);
}

.calc-input.phone input:focus-visible,
.area-input:focus-visible,
.area-input:focus-visible~.area-input-ending {
    box-shadow: 0 0 .5vh var(--main-color),
        0 0 .5vh var(--main-color) inset;
}

@media (orientation:portrait) {
    .calc-spacer {
        height: 0;
    }

    .calc-input>p.large {
        display: none;
    }

    .calc-input>p.small {
        display: inline;
        font-size: 3.2vh;
        width: 50%;
    }

    .calc-input>*:last-child {
        width: 50%;
    }

    .calc-portrait-only {
        display: block;
    }

    .calc form {
        align-items: stretch;
        border: none;
        padding: 0 5vw;
    }

    .calc {
        flex-direction: column;
        width: 100%;
    }

    .calc .facture-radio-holder {

        display: none;
    }

    .calc #facture {
        display: block;
    }

    .calc>form>.heading {
        display: none;
    }

    .calc-placeholder {
        display: block;
        margin: 0 auto;
        border: none;
        width: 84%;
        height: 50vh;
    }
}