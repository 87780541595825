.feedback {
    position: relative;
    width: 90%;
    height: 77vh;
    margin-left: 5vw;
    margin-right: 5vw;
    margin-bottom: 3vh;
}

.feedback .phone {
    position: absolute;
}

.feedback .phone img {
    position: absolute;
}

.feedback .phone {
    height: 77vh;
    width: 43.7576687vh;
    border-radius: 7vh;
    overflow: hidden;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
}

.feedback .phone-1 {
    z-index: -2;
    transform: translateX(-30vw) scale(0.75);
}

.feedback .phone-3 {
    z-index: -2;
    transform: translateX(30vw) scale(0.75);
}
.feedback .phone-2{
    z-index: -1;
}

.feedback .phone>* {
    transition: all .5s;
}

.feedback .image,
.feedback .frame {
    object-fit: contain;
}

.feedback .image {
    width: 100%;
    height: 100%;
    translate: -50% 0;
    left: 50%;
}

.feedback .frame {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.feedback .image-holder {
    position: absolute;
    width: 76%;
    height: 95%;
    left: 50%;
    top: 50%;
    translate: -50% -50%;
    border-radius: 5%;
    overflow: hidden;
}

.feedback .image-holder>* {
    transition: all .5s
}

.feedback .buttons-holder {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.feedback .image.curr{
    translate: -50%;
}
.feedback .image.next{
    translate: 50%;
}
.feedback .image.prev{
    translate: -150%;
}

.feedback .phone-1 .image{
    transition-delay: .15s;
}
.feedback .phone-3 .image{
    transition-delay: .3s;
}

.feedback-call{
    width: min(80vh, 90vw);
    margin-left: auto;
    margin-right: auto;
}

@media (orientation: portrait) {

    .feedback .phone-2 .image{
        transition-duration: .8s;
    }

    .feedback .phone-1,
    .feedback .phone-3 {
        display: none;
    }

    .feedback .phone {}
}