/*Navigation bar*/
.custom-navbar {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1020;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: center;
}

.custom-nav-link {
    position: relative;
    animation-name: appear;
    animation-duration: 1s;
}

.logo {
    height: 37.5%;
    object-fit: contain;
    cursor: pointer;
}

.nav-burger {
    height: 4.2vh;
    width: 4.2vh;
    display: inline;
    position: relative;
    background-image: url('../../../images/burger.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.nav-head {
    display: flex;
    font-size: 2.2vh;
    align-self: stretch;
    flex-grow: 1;
    justify-content: space-evenly;
}

.nav-head p {
    margin: 0 !important;
    color: white;
}

.nav-items>.link-unstyled {
    padding-left: 1vw;
    padding-right: 1vw;
}

.under-link {
    background-color: var(--main-color);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    transform: translateY(-100%);
    transition: transform .15s;
}

a:hover>.under-link {
    background-color: hsl(from var(--main-color) h s calc(l * 1.1));
}

a:hover>.under-link,
.current>.under-link {
    transform: translateY(0);
}

.nav-items {
    display: flex;
    justify-content: space-evenly;
    flex-shrink: 1;
    flex-grow: 1;
}

.bi-chevron-down {
    transform: none;
    transition: 1s;
}

/*vertical offcanvas*/
.offcanvas-backdrop {
    background-color: #2B2A29;
}

.offcanvas-backdrop.show {
    opacity: .75;
}

.offcanvas-header {
    justify-content: center;
}

.offcanvas-header img {
    height: 16.7vh;
    padding-bottom: 5vh;
}

.offcanvas {
    background-color: #2B2A29;
    opacity: 0.9;
    width: 38.5vh !important;
}

.off-pt {
    padding-top: 4.5vh;
}

/*offcanvas lang selector*/


.offcanvas-text {
    text-align: center;
}

.offcanvas p {
    font-family: var(--open-sans);
    font-size: 4vh;
    color: white;
}

.offcanvas-text .selected {
    color: var(--main-color);
}

/* body */
.offcanv-body {
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;
}

.offcanvas-close {
    color: var(--main-color) !important;
    font-size: 4vh;
    flex: 0 0 auto;
    width: 15%;
    align-self: center;
    justify-self: stretch;
}

.navigations {
    display: flex;
    flex-direction: column;
    gap: .65vh;
}

.link-unstyled,
.link-unstyled:link,
.link-unstyled:hover {
    color: white;
    text-decoration: inherit;
    display: flex;
    align-items: center;
    text-wrap: nowrap;
}

.d-head {
    font-family: 'Viaoda Libre', 'Times New Roman', Times, serif !important;
    font-size: 3.7vh !important;
}

.data a:link>p {
    font-size: 2vh !important;
    color: var(--main-color);
    text-decoration: none !important;
}

.d-range {
    font-weight: 100;
    font-size: 2.857vh !important;
}

.data {
    padding-top: 2.5vh;
}

/*45 34 667*/
@keyframes appear {
    0% {
        top: -50px;
        opacity: 0;
    }

    100% {
        top: 0px;
        opacity: 100%;
    }
}

@media (orientation: portrait) {
    .under-link {
        display: none;
    }

    .nav-head {
        display: none;
    }

    .outer-div {
        display: block;
    }

    .custom-navbar {
        background-color: transparent;
        height: 10vh;
        background-color: black;
        justify-content: space-between;
        padding-left: 5%;
        padding-right: 5%;
    }
}

@media (orientation: landscape) {
    .link-unstyled {
        transform: skew(-37deg);
    }

    .link-unstyled>p,
    .link-unstyled>.phone-number {
        transform: skew(37deg);
    }

    .offcanvas {
        display: none !important;
    }

    .offcanvas-backdrop {
        display: none !important;
    }

    .outer-div {
        display: none;
    }

    .logo {
        height: 65%;
    }

    .nav-burger {
        display: none;
        height: 7.2vh;
    }

    .custom-navbar {
        z-index: 1030;
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        justify-content: space-between;
        padding-left: 1.5vw;
        padding-right: 5vw;

        background-color: rgba(0, 0, 0, .5);
        height: 8vh;
    }


    @media (min-width: 135vh) {

        .logo {
            height: 95%;
        }

        .custom-navbar {
            padding-left: 5vw;
        }
    }
}