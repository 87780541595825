body {
    background-image: url(./images/bg.svg);
}

.bg-custom {
    background-image: url(./images/bg.svg);
}

:root {
    --main-color: #FF9B00;
    --anim-dur: 1s;
    --icon-size: 5.438rem;
    --bg-url: url(./images/bg.svg);
    --open-sans: 'Open Sans', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    --readex-pro: "Readex Pro", sans-serif;
    --main-gradient: linear-gradient(180deg, var(--main-color) 0%,
            hsl(from var(--main-color) h s calc(l * .9)) 25%,
            hsl(from var(--main-color) h s calc(l * .84)) 39%,
            hsl(from var(--main-color) h s calc(l * .7)) 75%,
            hsl(from var(--main-color) h s calc(l * .6)) 100%);
    --main-dark-gradient: linear-gradient(to right, var(--main-color) 0%,
            hsl(from var(--main-color) h s calc(l * .90)) 25%,
            hsl(from var(--main-color) h s calc(l * .84)) 41%,
            hsl(from var(--main-color) h s calc(l * .76)) 61%,
            hsl(from var(--main-color) h s calc(l * .68)) 78.5%,
            hsl(from var(--main-color) h s calc(l * .60)) 100%);

    font-size: 0.75vw;
}


a:link {
    color: var(--main-color);
    text-decoration-color: rgba(0, 0, 0, 0);
}

a:visited:hover {
    color: #bf8630;
    text-decoration-color: #bf8630;
}

a:hover {
    color: #ffb340;
    text-decoration-color: #ffb340;
}

a:visited {
    color: #bf7300;
    text-decoration-color: #bf7300;
}

.heading-text {
    font-family: 'Viaoda Libre', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.orange {
    color: var(--main-color)
}