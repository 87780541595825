.faq-holder {
    display: flex;
    width: 90vw;
    margin: 5vh auto;
    gap: 5vw
}

.faq-holder>*:first-child {
    width: 57.5%;
}

.faq-holder>*:last-child {
    width: 37.5%;
}

.faq-anim-in {
    border-width: 0;
    max-height: 0;
    overflow: hidden;
    animation: fadeInAnimation ease-in .5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
    0% {
        border-width: 0;
        max-height: 0;
    }

    100% {
        border-bottom-width: 2px;
        max-height: 80vh;
    }
}

.faq-holder button {
    display: block;
    margin: 8vh auto 0 auto;
    border: 0;
    font-size: max(12px, 2vmin);
    padding: 1.5% 3%;
    background-image: var(--main-dark-gradient);
    color: white;
    font-family: var(--open-sans);
}

.faq-button-exit-active {
    margin: 0 auto !important;
    max-height: 0;
    padding: 0 !important;
    opacity: 0;
    transition: all .2s;
}

@media (orientation: portrait) {
    .faq-holder>*:first-child {
        width: 100%;
    }

    .faq-holder>*:last-child {
        display: none;
    }
}