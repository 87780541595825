.dot {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    background-color: #444444;
    border-radius: 100%;
    width: 10px;
    height: 10px;
}

.dot-2 {
    transform: translateX(-50%);
}

.dot-1 {
    transform: translateX(-200%);
}

.dot-3 {
    transform: translateX(100%);
}

.dot.dot-active {
    background-color: var(--main-color);
    transition: transform .5s
}

.material {
    width: 80%;
    position: absolute;
    transform: translateX(-50%);
    transition: all .5s;
    height: 54vh;
}

.material-modal-backdrop {
    background-color: #000000cc;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1500;
    transition: all .5s;
    opacity: 0;
}


.material-modal {
    background-color: #00000080;
    border-radius: 2vh;
    border-color: var(--main-color);
    border-width: 1px;
    border-style: solid;
    font-style: italic;
    line-height: 1.2;
    text-align: center;
    padding: 3%;
    font-size: 2vh;
    padding: auto 25vw;
    position: fixed;
    z-index: 1600;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: all .5s;
}

.material-images-holder>hr {
    position: absolute;
    width: 90%;
    left: 50%;
    transform: translateX(-50%);
    margin: 0;
    top: 51vh;
}

.material-images-holder>hr:first-child {
    top: 42vh;
}

.material-modal .bi {
    cursor: pointer;
    color: var(--main-color);
    position: absolute;
    top: 0;
    right: 0;
    font-size: 2.5vh;
    transform: translate(50%, -50%);
}

.material-modal-wrapper p {
    display: block;
}

.material-modal-wrapper button {
    display: none;
}

.material-more {
    margin: auto 0;
    background-color: transparent;
    border-color: transparent;
    font-size: 3.2vh;
}

.material-more .bi {
    color: var(--main-color)
}

.material-title {
    display: flex;
    justify-content: space-between;
    margin: 0 -5vw;
    text-wrap: nowrap;
}

.material-buttons-holder {
    padding: 0 5vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 40vh;
    z-index: 3;
}

.material-images-holder {
    position: relative;
    height: 54vh;
    overflow: hidden;
}

.material h1 {
    font-family: var(--open-sans);
    font-size: 4vh;
    padding: 4vh 2vh;
}

.material p {
    display: none;
    font-size: 2.2vh;
    line-height: 1.1;
    font-style: italic;
    padding: 0 2vw 4vh 2vw;
    text-align: center;
}

.material-img {
    width: 90%;
    margin: 0 5%;
    height: 40vh;
    object-fit: cover;
}

.material-next {
    left: 150%;
}

.material-prev {
    left: -50%;
}

.material-curr {
    left: 50%;
}

.back-move {
    display: none;
}

@media (orientation: landscape) {
    .dot{
        display: none;
    }
    .material-images-holder>hr {
        display: none;
    }

    .material-img {
        width: 100%;
        margin: 0;
    }

    .material-more {
        display: none;
    }

    .material-title {
        justify-content: center;
    }

    .material-images-holder {
        height: 75vh;
    }

    .material h1 {
        padding: 2vh;
    }

    .material-buttons-holder {
        height: 100%;
    }

    .material p {
        display: block;
    }

    .material {
        background-image: var(--bg-url);
        width: 25vw;
        height: 73vh;
        border-color: var(--main-color);
        border-width: 2px;
        border-style: solid;
        text-align: center;
    }

    .material-img {
        height: 30vh;
    }

    .material-prev {
        left: 50%;
        transform: translateX(-150%) scale(0.8);
    }

    .material-next {
        left: 50%;
        transform: translate(50%) scale(0.8);
    }

    .back-move {
        display: block;
        z-index: -1;
    }

    @media (max-width: 150vh) {

        .material {
            width: 40vw;
        }

        .material p {
            font-size: 2.4vh;
        }

        .material-curr {
            z-index: 2;
        }

        .material-prev {
            transform: translateX(-100%) scale(0.8);
        }

        .material-next {
            transform: translate(0%) scale(0.8);
        }
    }
}