.link {
    color: white !important;
    text-wrap: nowrap;
    line-height: 1.2;
}

.phone-number {
    text-wrap: nowrap;
    display: flex;
    position: relative;
}

.phone-number>.link:last-of-type,
.phone-number>.link:first-of-type {
    opacity: 0;
    position: absolute;
    transition: all .075s;
    top: 0%;
}


.phone-number:hover>.link:last-of-type,
.phone-number:hover>.link:first-of-type {
    opacity: 1;
}

.phone-number:hover>.link:first-of-type {
    top: -100%
}

.phone-number:hover>.link:last-of-type {
    top: 100%;
}

.phone-number:hover>.bi-chevron-down {
    transform: rotate(90deg);
}


@media (orientation: landscape) {
    .phone-number>a {
        display: none;
    }

    .phone-number .popup-n div {
        display: none;
        animation: popup-appear .1s forwards;
        animation-delay: .5s;
    }

    .phone-number .popup-n:hover div {
        display: flex;
        animation-delay: .0s;
    }

    .phone-number .popup-n div {
        position: absolute;
        flex-direction: column;
        padding: 10%;
        translate: -50%;
        left: 50%;
        background-color: #000000cc;
        border-radius: .5vh;
    }

    @media (min-width: 135vh) {

        .phone-number>a {
            display: inline;
        }

        .phone-number .popup-n {
            display: none !important;
        }

        .bi-telephone-forward {
            display: none;
        }
    }

    @keyframes popup-appear {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }
}