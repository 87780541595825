.adv-title {
    flex-shrink: 0;
    font-size: 6vmin;
}

.adv-title-flex {
    color: white;
    padding-top: 5vh;
    display: flex;
    align-items: center;
    gap: 2.5vw;
    padding-left: 5vw;
    padding-right: 5vw;
    padding-bottom: 3vh;
}


.adv-title-flex hr {
    border-width: 3px;
    margin: 0 !important;
}

.adv-title-flex>hr:first-child {
    width: 18%;
}

.adv-header {
    font-family: var(--open-sans);
    flex-grow: 1;
    text-align: end;
}

.adv-header hr {
    width: 100%;
}

.adv-header h1 {
    line-height: 3;
    margin: 0;
    font-size: 6vh;
    color: var(--main-color);
    display: inline-block;
    margin-right: 1vw;
    vertical-align: middle;
}

.adv-header h3 {
    text-align: start;
    margin: 0;
    display: inline-block;
    font-size: min(3vh, 2.5vw);
    vertical-align: middle;
}

.adv-header span {
    color: var(--main-color);
    font-weight: 100;
    font-family: var(--readex-pro);
}

.adv-single-title {
    font-size: 3vh;
    display: flex;
    align-items: center;
}

.adv-num-holder {
    color: var(--main-color);
    margin: 1.5vh;
    flex-shrink: 0;
    width: 6vh;
    height: 6vh;
    text-align: center;
    border: solid 2px var(--main-color);
    display: flex;
    transform: rotate(45deg);
}

.adv-num-holder p {
    transform: rotate(-45deg);
    margin: auto;
}

.adv-single-title-text {
    font-size: inherit;
    text-align: center;
    margin: auto;
}

.adv-small {
    display: none;
}

.adv-text {
    padding-top: 1vh;
    font-size: 2.3vh;
    font-style: italic;
    font-weight: light;
    text-align: justify;
}

.adv-grid {
    display: flex;
    color: white;
    padding-top: 2vh;
    padding-left: 5vw;
    padding-right: 5vw;
    flex-wrap: wrap;
    gap: 5%;
    row-gap: 5vh;
}

.adv-grid>* {
    width: 30%;
}

.triangle svg {
    display: none;
}

.triangle-text-holder {
    display: none;
}

@media (max-width: 120vh) {
    .adv-grid>* {
        width: 45%;
    }

    .adv-grid {
        gap: 10%;
        row-gap: 5vh;
    }
}

@media (orientation: portrait) {
    .tri-text{
        width: 45vw;
        height: 38.9711431703vw;
    }

    .triangle-text-holder {
        display: unset;
        width: 100%;
        top: 0;
        left: 0;
        position: absolute;
        overflow: hidden;
    }

    .triangle-text-holder div:not(.tri-text) {
        width: 22.5vw;
        height: 38.9711431703vw;
        shape-margin: '1.5vw';
    }

    .triangle-header {
        transition: all .5s;
        position: absolute;
        display: flex;
        bottom: 0;
        justify-content: space-between;
        align-items: center;
        padding-left: 6.5vw;
        padding-right: 6.5vw;
        width: 45vw !important;
        height: 38.9711431703vw;
        scale: 0 1;
    }

    .triangle-header.show {
        scale: 1 1;
    }

    #triangle-1 .triangle-header {
        transform: translateY(16.666666666%) rotate(-60deg);
    }

    #triangle-3 .triangle-header {
        transform: translateY(16.666666666%) rotate(60deg);
    }

    #triangle-4 .triangle-header {
        transform: translateY(-16.666666666%) rotate(60deg);
    }

    #triangle-6 .triangle-header {
        transform: translateY(-16.666666666%) rotate(-60deg);
    }

    #triangle-1 .triangle-header,
    #triangle-3 .triangle-header,
    #triangle-5 .triangle-header {
        padding-top: 9.75vw;
        padding-bottom: 3.25vw;
    }


    #triangle-2 .triangle-header,
    #triangle-4 .triangle-header,
    #triangle-6 .triangle-header {
        padding-top: 3.25vw;
        padding-bottom: 9.75vw;
    }

    #triangle-1 .triangle-header,
    #triangle-2 .triangle-header,
    #triangle-3 .triangle-header {
        flex-direction: column-reverse;
    }

    #triangle-4 .triangle-header,
    #triangle-5 .triangle-header,
    #triangle-6 .triangle-header {
        flex-direction: column;
    }


    .triangle-text-holder div:first-of-type:not(.tri-text) {
        float: left;
    }

    .triangle-text-holder div:last-of-type:not(.tri-text) {
        float: right;
    }

    .triangle svg {
        display: block;
    }

    .triangle {
        position: absolute;
        display: inline;
        width: 45vw;
    }

    .triangle path {
        fill: #0000007f;
        stroke: var(--main-color);
    }

    .adv-grid {
        position: relative;
        padding-top: 0;
        margin-top: 2vh;
        height: calc(0.8660254 * 90vw);
        display: block;
    }

    #triangle-4,
    #triangle-5,
    #triangle-6 {
        bottom: 0;
    }

    #triangle-4,
    #triangle-1 {
        left: 5vw;
    }

    #triangle-5,
    #triangle-2 {
        left: 50%;
    }

    #triangle-6,
    #triangle-3 {
        right: 5vw;
    }

    #triangle-2 {
        transform: translateX(-50%) rotate(180deg);
    }

    #triangle-4 {
        transform: rotate(180deg);
    }

    #triangle-5 {
        transform: translateX(-50%);
    }

    #triangle-6 {
        transform: rotate(180deg);
    }

    .adv-text {
        display: none;
    }

    .adv-small {
        display: block;
        font-size: 1.9vw;
        text-align: center;
        padding-top: 2vw;
        font-style: italic;
        height: 38.971143170279994vw;
        letter-spacing: .2vw;
        line-height: 1;
        font-size: 2.5vw;
        transition: all .5s;
        transform: scaleX(0);
    }

    .adv-small.show {
        transform: scaleX(1);
    }

    #triangle-2 .adv-small,
    #triangle-4 .adv-small,
    #triangle-6 .adv-small{
        padding-top: 1.5vw;
    }

    .adv-info {
        clip-path: polygon(-20% 120%, 50% 0, 120% 120%);
        position: absolute;
    }

    .adv-info>div:first-of-type {
        display: none;
    }

    .adv-title-flex {
        display: none;
    }

    .adv-num-holder {
        display: none;
    }

    .triangle-title{
        text-align: center;
        font-size: 3vw;
    }
}