.custom-accordion {
    width: 90vw;
    margin-left: auto;
    margin-right: auto;
    color: white;
    font-size: max(12px, 2vmin);
    font-style: italic;
    font-family: var(--open-sans);
    font-weight: 250;
    letter-spacing: .1em;
}

.custom-accordion-header {
    font-weight: bold;
    font-style: normal;
    padding: 2.5vh;
    font-size: max(14px, 3vmin);
    display: flex;
    justify-content: space-between;
}

.custom-accordion-header .number {
    font-size: larger;
    font-weight: lighter;
    margin-right: 2vw;
}

.custom-accordion-header p {
    vertical-align: middle;
}

.custom-accordion-header .open-button {
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    height: 100%;
    transition: all .2s;
    color: var(--main-color);
}

.custom-accordion .step-holder {
    border-bottom: solid 2px var(--main-color);
}