.popup {
    width: 120%;
    position: absolute;
    left: -10%;
    top: 100%;
    display: none;
}

.popup i {
    opacity: 0;
}

*:hover>.popup {
    display: block;
}

*:hover>.popup>* {
    animation-name: appear;
    animation-duration: .2s;
    animation-fill-mode: forwards;
}

*>.popup>* {
    opacity: 0;
}



.popup p {
    text-align: center;
}

@keyframes appear {
    0% {
        transform: translateY(-100%);
        opacity: 0;
    }

    100% {
        transform: translateY(0%);
        opacity: 1;
    }
}

@media (min-width: 135vh) {
    .popup>a:first-child {
        display: none;
    }

    .popup {
        bottom: 0%;
    }
}