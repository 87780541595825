.after-steps,
.steps-title {
    font-size: max(16px, 4vmin);
    color: white;
    font-family: var(--open-sans);
    font-weight: 300;
    margin-left: auto;
    margin-right: auto;
    text-align: end;
    margin-bottom: 3vh;
}

.steps-title {
    width: 90vw;
    text-transform: uppercase;

}

.after-steps {
    margin: 5vh auto;
    text-align: center;
    width: 80vw;
}


@media (orientation: portrait) {
    .steps-title {
        text-align: center;
    }
}