.social-container {
    display: block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: all .2s
}

/* Hide the browser's default radio button */
.social-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.social-container input:checked~.social {
    box-shadow: 0 0 1.4vmax var(--main-color);
}

.social-container input:hover~.social {
    transform: scale(1.1);
}

.social-container p {
    transition: all .2s;
}


.calc-input .social {
    transition: all .5s;
    margin-left: 1vw;
    width: 5vh;
    height: 5vh;
    border-radius: 50%;
    mask-repeat: no-repeat;
    mask-size: contain;
    mask-position: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.calc-input .telegram {
    background-image: url(../../../images/socials/Telegram.svg);
}

.calc-input .whatsapp {
    background-image: url(../../../images/socials/WhatsApp.svg);
}

.calc-input .viber {
    background-image: url(../../../images/socials/Viber.svg);
}

.calc-input .signal {
    background-image: url(../../../images/socials/Signal.svg);
}

@media (orientation: portrait) {
    .calc-input .social {
        margin: 0;
        border-radius: 0;
        height: 100%;
        width: 100%;
    }

    .calc .social-container {
        height: 5vh;
        width: 5.5vh;
        border: solid 3px transparent;
        background-image: linear-gradient(#222, #222), linear-gradient(var(--main-color) 0%,
                hsl(from var(--main-color) h s calc(l * .88)) 16.5%,
                hsl(from var(--main-color) h s calc(l * .78)) 33%,
                hsl(from var(--main-color) h s calc(l * .62)) 55%,
                hsl(from var(--main-color) h s calc(l * .56)) 79.5%,
                hsl(from var(--main-color) h s calc(l * .32)) 100%);
        background-origin: border-box;
        background-clip: padding-box, border-box;
        padding: 1vh;
    }

    .calc-input .telegram {
        -webkit-mask-image: url(../../../images/socials/TelegramOrange.svg);
        mask-image: url(../../../images/socials/TelegramOrange.svg);
        background-image: none;
        background-color: var(--main-color);
    }

    .calc-input .whatsapp {
        -webkit-mask-image: url(../../../images/socials/WhatsAppOrange.svg);
        mask-image: url(../../../images/socials/WhatsAppOrange.svg);
        background-image: none;
        background-color: var(--main-color);
    }

    .calc-input .viber {
        -webkit-mask-image: url(../../../images/socials/ViberOrange.svg);
        mask-image: url(../../../images/socials/ViberOrange.svg);
        background-image: none;
        background-color: var(--main-color);
    }

    .calc-input .signal {
        -webkit-mask-image: url(../../../images/socials/SignalOrange.svg);
        mask-image: url(../../../images/socials/SignalOrange.svg);
        background-image: none;
        background-color: var(--main-color);
    }

    .calc-input .social-container {
        border-left-width: 0;
    }

    .calc-input .social-container:first-child {
        border-left-width: 3px;
        border-top-left-radius: 3vh;
    }

    .calc-input .social-container:last-child {
        border-bottom-right-radius: 3vh;
    }

    .social-container:has(>input:checked) {
        box-shadow: inset 1vh var(--main-color);
        background-image: linear-gradient(#22222280, #22222280), linear-gradient(var(--main-color) 0%,
                hsl(from var(--main-color) h s calc(l * .88)) 16.5%,
                hsl(from var(--main-color) h s calc(l * .78)) 33%,
                hsl(from var(--main-color) h s calc(l * .62)) 55%,
                hsl(from var(--main-color) h s calc(l * .56)) 79.5%,
                hsl(from var(--main-color) h s calc(l * .32)) 100%);
    }

    .social-container input:checked~.whatsapp {
        background-color: #25D366;
    }

    .social-container input:checked~.viber {
        background-color: #7360F2;
    }

    .social-container input:checked~.telegram {
        background-color: #2AABEE;
    }

    .social-container input:checked~.signal{
        background-color: #3A76F0;
    }

    .social-container input:hover~.social {
        transform: scale(1.1);
    }
}