.fade-enter:not(.mirrored) {
    transform: perspective(300px) rotate3d(0, 1, 0, 90deg);
}

.fade-exit:not(.mirrored) {
    transform: perspective(300px) rotate3d(0, 1, 0, 0deg);
}

.fade-enter-active:not(.mirrored) {
    transform: perspective(300px) rotate3d(0, 1, 0, 0deg);
}

.fade-exit-active:not(.mirrored) {
    transform: perspective(300px) rotate3d(0, 1, 0, -90deg);
}

.fade-enter.mirrored {
    transform: perspective(300px) rotate3d(0, 1, 0, -90deg);
}

.fade-exit.mirrored {
    transform: perspective(300px) rotate3d(0, 1, 0, 0deg);
}

.fade-enter-active.mirrored {
    transform: perspective(300px) rotate3d(0, 1, 0, 0deg);
}

.fade-exit-active.mirrored {
    transform: perspective(300px) rotate3d(0, 1, 0, 90deg);
}

.fade-enter-active,
.fade-exit-active {
    transition: all 500ms;
}