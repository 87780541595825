.color-container {
  color: var(--main-color);
  display: block;
  position: relative;
  cursor: pointer;
  font-size: max(3vmin,16.8px);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.color-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  display: none;
}

.color-container:hover input+div {
  border: solid 3px #ffba39;
}

.color-container input:checked+div {
  border: solid 3px var(--main-color);
}

.color-container input:checked+div>.checkmark {
  display: block;
}