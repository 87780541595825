.arrow {
    fill: var(--main-color);
}

.arrow-holder {
    object-fit: contain;
    width: 260px;
    height: 50px;
    flex-shrink: 1;
    flex-grow: 1;
    height: 100%;
    min-height: 10vh;
    max-width: 30vw;
    min-width: 20vw;
}

.why-heading {
    font-size: calc(2.5vh + 2.5vw);
    text-align: center;
}

.why-heading-holder {
    display: flex;
    justify-content: start;
    gap: 5vw;
    padding-left: 5vw;
    padding-right: 5vw;
    padding-top: 5vh;
}

.why-badge>h3 {
    font-size: 5vh;
}

.why-badge>p {
    font-size: 5vh
}

hr {
    border-color: var(--main-color) !important;
    /* border-width: 3px !important; */
    opacity: 1 !important;
    width: 90%;
    margin-left: auto !important;
    margin-right: auto !important;
    padding: auto;
}

hr.bold {
    border-width: 3px !important;
}


.why-props {
    display: flex;
    gap: 5vw;
}

.why-badge>h3 {
    font-size: 5vh;
}

.why-badge>p {
    font-size: 3vh;
}

.why-badge {
    font-family: var(--open-sans);
    font-weight: 300;
}


@media (orientation: landscape) {
    .why-props>.why-badge {
        width: 25%;
    }

    .why-badge>hr {
        margin-left: 0 !important;
        margin-right: 0 !important;
        width: 100%;
    }

    .why-props {
        justify-content: space-evenly;
        padding-left: 5vw;
        padding-right: 5vw;
    }

    hr.portrait {
        display: none !important;
    }

    .arrow.arrow-vr {
        display: none;
    }

    .why-heading {
        display: inline-block;
    }

    .arrow {
        display: inline-block;
    }
}

@media (orientation: portrait) {
    .why-badge>h3 {
        font-size: 6vw;
    }

    .why-badge>p {
        font-size: 3.6vw;
    }

    .why-badge>hr {
        width: 90%;
    }

    .why-badge {
        text-align: center;
    }

    .why-props {
        flex-direction: column;
    }

    hr {
        width: 100%;
    }

    .why-heading {
        text-wrap: wrap;
        align-items: center;
    }

    .arrow.arrow-hr {
        display: none;
    }

    .arrow-holder {
        align-self: stretch;
        flex-grow: 1;
        min-height: 20vw;
        min-width: 87vw;
    }

    .why-heading-holder {
        flex-direction: column;
        align-items: center;
    }
}