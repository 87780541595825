.discount-circle-holder .circle-1 {
    opacity: 0;
    --top: 120%;
    --left: 12.871287128712872%;
}

.discount-circle-holder .circle-2 {
    opacity: 0;
    --top: -20%;
    --left: 37.623762376237624%;
}

.discount-circle-holder .circle-3 {
    opacity: 0;
    --top: 120%;
    --left: 62.37623762376238%;
}

.discount-circle-holder .circle-4 {
    opacity: 0;
    --top: -20%;
    --left: 87.12871287128713%;
}

.circle-enter.circle-1,
.circle-enter.circle-3 {
    --top: 120%
}

.circle-enter.circle-2,
.circle-enter.circle-4 {
    --top: -20%
}

.circle-enter-active.circle {
    transition: all 1s ease-in-out;
    opacity: 1;
}

.circle-enter-active.circle-1 {
    --top: 47.17514124293785%;
    --left: 12.871287128712872%;
}

.circle-enter-active.circle-2 {
    transition-delay: .25s;
    --top: 52.82485875706214%;
    --left: 37.623762376237624%;
}

.circle-enter-active.circle-3 {
    transition-delay: .5s;
    --top: 47.17514124293785%;
    --left: 62.37623762376238%;
}

.circle-enter-active.circle-4 {
    transition-delay: .75s;
    --top: 52.82485875706214%;
    --left: 87.12871287128713%;
}

.circle-enter-done.circle-1 {
    opacity: 1;
    --top: 47.17514124293785%;
    --left: 12.871287128712872%;
}

.circle-enter-done.circle-2 {
    opacity: 1;
    --top: 52.82485875706214%;
    --left: 37.623762376237624%;
}

.circle-enter-done.circle-3 {
    opacity: 1;
    --top: 47.17514124293785%;
    --left: 62.37623762376238%;
}

.circle-enter-done.circle-4 {
    opacity: 1;
    --top: 52.82485875706214%;
    --left: 87.12871287128713%;
}

.room-enter {
    opacity: 0;
    --addition: 10%;
}

.room-enter-active {
    opacity: 1 !important;
    --addition: 0%;
    transition: all 1.5s ease-out;
    transition-delay: 2s;
}


.room-enter-active.room-2{
    transition-delay: 2.5s;
}
.room-enter-active.room-3{
    transition-delay: 3s;
}
.room-enter-active.room-4{
    transition-delay: 3.5s;
}

.room-enter-done {
    opacity: 1 !important;
    --addition: 0%;
}