.sliders-holder {
    margin-top: 3vh;
    padding-left: 5vw;
    padding-right: 5vw;
    overflow: auto;
    width: 100%;
    text-wrap: nowrap;
    overflow: hidden;
    position: relative;
}

.ex-img-holder {
    width: 44vw;
    height: 50vh;
    display: inline-block;
    transition: all 1s;
    margin-right: 1vw;
}

.sliders-holder img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    vertical-align: top;
}

.inner-slider-holder {
    position: relative;
    transition: all 1s;
}

.s-buttons-holder {
    display: none;
}


.sl-h-0,
.sl-h-7 {
    left: 0;
}

.sl-h-1,
.sl-h-8 {
    left: -90vw;
}

.sl-h-2,
.sl-h-9 {
    left: -180vw;
}

.sl-h-3,
.sl-h-10 {
    left: -270vw;
}

.sl-h-4,
.sl-h-11 {
    left: -360vw;
}

.sl-h-5,
.sl-h-12 {
    left: -450vw;
}

.sl-h-6,
.sl-h-13 {
    left: -540vw;
}


@media (orientation: portrait) {
    .sliders-holder {
        padding-left: 0;
        padding-right: 0;
    }

    .s-buttons-holder {
        display: flex;
        justify-content: space-between;
        padding-left: 5vw;
        padding-right: 5vw;
        position: absolute;
        width: 100%;
        height: 100%;
        align-items: center;
        z-index: 1;
    }

    .ex-img-holder {
        width: 100%;
        margin: 0;
        height: 33vh;
    }

    .sl-h-0 {
        left: 0;
    }

    .sl-h-1 {
        left: -100%;
    }

    .sl-h-2 {
        left: -200%;
    }

    .sl-h-3 {
        left: -300%;
    }

    .sl-h-4 {
        left: -400%;
    }

    .sl-h-5 {
        left: -500%;
    }

    .sl-h-6 {
        left: -600%;
    }

    .sl-h-7 {
        left: -700%;
    }

    .sl-h-8 {
        left: -800%;
    }

    .sl-h-9 {
        left: -900%;
    }

    .sl-h-10 {
        left: -1000%;
    }

    .sl-h-11 {
        left: -1100%;
    }

    .sl-h-12 {
        left: -1200%;
    }

    .sl-h-13 {
        left: -1300%;
    }
}