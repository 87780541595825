.squares-holder {
    position: relative;
    height: min(40vw, 80vh);
    margin-bottom: 3vmin
}

.outer-sq {
    --vh: min(33vw, 58vh);
    left: 50%;
    translate: -50% -6%;
    position: absolute;
    border: solid 3px var(--main-color);
    width: var(--vh);
    height: var(--vh);
    text-align: center;
    transform: rotate(45deg);
    padding: calc(var(--vh)* 0.1);
    transition: all .5s;
    scale: 0.6;
}

.inner-sq {
    box-sizing: border-box;
    height: 100%;
    transform: rotate(-45deg);
    color: white;
    font-size: min(6vw, 10vh);
    font-family: var(--open-sans);
}

.inner-sq p:last-child {
    font-size: min(4vw, 6vh);
    font-weight: 250;
}

.sq-1 {
    translate: -150% -6%
}

.sq-2 {
    translate: -100% 44%;
}

.sq-3 {}

.sq-4 {
    translate: 0 44%;
}

.sq-5 {
    translate: 50% -6%;
}

.outer-sq.image {
    padding: 0;
    overflow: hidden;
}

.outer-sq.image>.inner-sq {
    background-image: url(../../../images/Steps.png);
    scale: 1.5;
    background-size: cover;
    background-position: center;
}

@media (orientation: landscape) {
    .inner-sq p:last-child{
        padding-top: 5%;
    }    
}