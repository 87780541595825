.modal-anim-enter .material-modal-backdrop {
    opacity: 0;
    transition: all .5s;
}

.modal-anim-enter-active .material-modal-backdrop {
    opacity: 1;
}

.modal-anim-enter-done .material-modal-backdrop {
    opacity: 1;
}

.modal-anim-enter .material-modal {
    transform: translate(-50%,-50%) scale(0);
    transition: all .5s;
}

.modal-anim-enter-active .material-modal {
    transform: translate(-50%,-50%) scale(1);
}

.modal-anim-enter-done .material-modal {
    transform: translate(-50%,-50%) scale(1);
}