.examples-text {
    font-size: max(3vmin, 14px);
    display: inline;
}

.examples-holder {
    padding-left: 5vw;
    padding-right: 5vw;
    padding-top: 3vh;
    display: flex;
    gap: 2vw;
}

.examples-holder>button{
    flex-shrink: 0;
}

@media (orientation: portrait)
{
    .examples-holder{
        display:block;
    }
    .examples-holder>button{
        display: none;
    }
}