:root {
    --anim-dur: .5s;
}

.slider-text {
    text-align: center;
    position: absolute;
    width: 40%;
    left: 60%;
    bottom: 0;
    -webkit-text-stroke: 5px black;
    font-weight: 300;
    font-size: 3vh;
    paint-order: stroke fill;
}

.carusel-img {
    width: 40%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    transform-origin: 100% 50%;
    transition: left var(--anim-dur), transform var(--anim-dur) calc(var(--anim-dur) / 2);
}

.curr-image {
    left: 60vw;
}

.prev-image {
    left: -35vw;

    transition: left var(--anim-dur) calc(var(--anim-dur) / 2), transform calc(var(--anim-dur) / 2);
    transform: scale(0.75) translate(0, 10vh)
}

.next-image {
    left: 100vw;
    transition-delay: calc(var(--anim-dur) / 2);
}

@media (orientation: portrait) {
    .next-image {
        transition-delay: 0s;
    }

    .curr-image {
        left: 0;
    }

    .prev-image {
        left: -100vw;
        transform: scale(1) translate(0, 0);
        transition-delay: 0s;
    }

    .carusel-img {
        width: 100%;
    }

    .slider-text {
        width: 100%;
        left: 0;
    }
}