.first-page {
    height: 90vh;
}

.slider {
    background-color: transparent;
    width: 100%;
    height: 99.9%;
    display: inline-block;
    position: absolute;
    overflow: hidden;
}

.info {
    background-color: transparent;
    width: 60%;
    height: 99.9%;
    display: inline-block;
    position: absolute;
}


.main {
    width: 100%;
    height: 100vh;
}

.dash {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
    color: var(--main-color);
}

.top,
.bot,
.sub-title {
    font-family: 'Viaoda Libre', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.top,
.bot,
.dash {
    font-weight: lighter;
    font-size: 6.5vh;
}

.top {
    letter-spacing: 1vh;
}

.title {
    padding-top: 3vh;
}

.sub-title {
    font-size: 2.9vh;
}

.main .buttons-holder {
    margin-top: 4vh;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2vh;
}

@media (orientation: portrait) {

    .info {
        background-color: rgba(0, 0, 0, .5);
        border: solid 3px var(--main-color);
        width: 34.6vh;
        top: 27.3vh;
        left: 50%;
        height: 37.78vh;
        transform: translateX(-50%);
    }

    .slider {
        width: 100%;
        height: 90vh;
        left: 0;
    }

    .main {
        height: 100vh;
        padding-top: 10vh;
    }
}

@media (orientation: landscape) {
    .first-page {
        height: 100vh;
    }

    .info {
        padding-left: 10vw;
        padding-right: 5vw;
    }
    
    .info>* {
        padding-top: 25vh;
        width: 100%;
        height: 100%;
        background-image: url('../../../images/bg.svg');
        box-shadow: inset 0 0 20px #2a2a2a, 0 0 20px #2a2a2a;
        border-radius: 20px;
    }

    .top,
    .bot,
    .dash {
        font-weight: lighter;
        font-size: 10vh;
    }

    .sub-title {
        padding-top: 3vh;
        font-size: 6vh;
        line-height: 1em;
    }

    .main .buttons-holder {
        gap: 4vh;
    }
}