.discount-landscape,
.discount-portrait {
    display: none;
    stroke-dasharray: 1058;
    stroke-dashoffset: -1058;
    animation: path-appear 2.5s cubic-bezier(.9, 0, .3, 1) forwards;
    animation-delay: .25s;
}

.discount-landscape path,
.discount-portrait path {
    fill: transparent;
    stroke: var(--main-color);
}

.discount-title {
    color: white;
    font-size: 7vmin;
    font-family: 'Viaoda Libre', sans-serif;
    text-align: center;
    margin: 5vmin auto;

}

.discount-circle-holder {
    aspect-ratio: 1.14124 / 1;
    color: white;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
}

.discount-circle-holder .circle h1 {
    position: absolute;
    display: inline;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    font-family: 'Viaoda Libre', sans-serif;
    font-size: 8vmin;
}

.discount-circle-holder .room {
    /* display: none; */
    top: var(--top);
    left: var(--left);
    position: absolute;
    font-size: 3.5vmin;
    font-family: var(--open-sans);
    translate: -50% -50%;
    width: max-content;
    opacity: 0;
}

.discount-circle-holder .circle {
    --width: 22.277227722772277%;
    --height: 25.423728813559322%;
    width: var(--width);
    height: var(--height);
    top: var(--top);
    left: var(--left);

    position: absolute;
    background-image: var(--main-gradient);
    border-radius: 50%;
    translate: -50% -50%;
}

.discount-circle-holder .room-1 {
    --top: calc(67.17514124293785% + var(--addition));
    --left: 12.871287128712872%;
}

.discount-circle-holder .room-2 {
    --top: calc(32.82485875706214% - var(--addition));
    --left: 37.623762376237624%;
}

.discount-circle-holder .room-3 {
    --top: calc(67.17514124293785% + var(--addition));
    --left: 62.37623762376238%;
}

.discount-circle-holder .room-4 {
    --top: calc(32.82485875706214% - var(--addition));
    --left: 87.12871287128713%;
}

@media (orientation: landscape) {
    .discount-circle-holder {
        height: 80vh;
    }

    .discount-landscape {
        display: block;
    }

}

@media (orientation: portrait) {
    .discount-circle-holder {
        aspect-ratio: 0.8762376237623762/1;
    }

    .discount-circle-holder .circle {
        top: var(--left);
        left: var(--top);
        width: var(--height);
        height: var(--width);
    }

    .discount-circle-holder .room {
        top: var(--left);
        left: var(--top);
    }

    .discount-portrait {
        display: block;
    }

    .discount-circle-holder .room-1,
    .discount-circle-holder .room-3 {
        --top: calc(75.17514124293785% + var(--addition));
    }
    
    .discount-circle-holder .room-2,
    .discount-circle-holder .room-4 {
        --top: calc(25.82485875706214% - var(--addition));
    }
}

@keyframes path-appear {
    0% {
        stroke-dashoffset: 1058;
    }

    100% {
        stroke-dashoffset: 0;
    }

}