.footer {
    font-size: 2.2vh;
    color: white;
    display: flex;
    flex-direction: row;
    padding: 0 5vw;
    margin-bottom: 1rem;
}

.footer p {
    margin: 0;
}

.footer>* {
    margin: auto !important;
}

.footer>a{
    all: unset;
    cursor: pointer;
}

@media (orientation: portrait) {
    .footer {
        display: none;
    }
    
}