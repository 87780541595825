.custom-item {
    width: 100%;
    font-size: 3vh;
    color: white !important;
    transition: all .1s;
}

.custom-item:hover {
    color: var(--main-color) !important;

}

.custom-item:active {
    background-color: var(--main-color) !important;
    color: white !important;
}

.custom-item.active {
    background-color: transparent !important;
    color: var(--main-color) !important;
}

.dropdown-menu.ddown {
    width: 100%;
}

