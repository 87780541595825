.spec {
    color: white;
    font-size: 1.5vw;
    position: relative;
    text-align: center;
    text-wrap: wrap;
    height: calc(3vh + 28.6017699115vw);
}

.spec .lines-holder {
    position: relative;
    left: 37%;
    width: 63%;
    height: 100%;
}

.spec>*,
.spec>*>* {
    position: absolute;
}

.spec .circle {
    border: solid 2px var(--main-color);
    width: 8.3vw;
    height: 8.3vw;
    border-radius: 50%;
    overflow: hidden;
    background-image: var(--bg-url);
}

.spec .circle div {
    width: 100%;
    height: 100%;
    background-color: var(--main-color);
    mask-repeat: no-repeat;
    mask-size: contain;
    mask-position: center;
}

.spec .text {
    width: 16vw;
}


.spec img {
    border: solid 2px var(--main-color);
    width: 100%;
}


.spec .first div {
    mask-image: url(../../../images/specials/Ok.svg);
    -webkit-mask-image: url(../../../images/specials/Ok.svg);
    mask-size: 80%;
}


.spec .second div {
    mask-image: url(../../../images/specials/Person.svg);
    -webkit-mask-image: url(../../../images/specials/Person.svg);
    mask-size: 73%;
}


.spec .third div {
    mask-image: url(../../../images/specials/TwoPerson.svg);
    -webkit-mask-image: url(../../../images/specials/TwoPerson.svg);
    mask-size: 92%;
}

.spec .first {
    transform: translateX(-50%);
    left: 20%;
    bottom: 60%;
}

.spec .second {
    transform: translateX(-50%);
    left: 40%;
    top: 60%;
}

.spec .third {
    transform: translateX(-50%);
    left: 60%;
    bottom: 60%;
}

.spec .fourth {
    transform: translateX(-50%);
    left: 80%;
    top: 60%;
}

.spec .text.first {
    bottom: unset;
    top: 60%;
}

.spec .text.second {
    top: unset;
    bottom: 60%;
}

.spec .text.third {
    bottom: unset;
    top: 60%;
}

.spec .text.fourth {
    top: unset;
    bottom: 60%;
}

.spec .fourth div {
    mask-image: url(../../../images/specials/Book.svg);
    -webkit-mask-image: url(../../../images/specials/Book.svg);
    mask-size: 80%;
    mask-position: 50% 38%;
}

.spec .lines-holder>svg {
    color: var(--main-color)
}


@media (orientation: portrait) {
    .spec {
        font-size: 2vw;
    }

    .spec img {
        display: none;
    }

    .spec .circle {
        width: min(9vh, 17.5vw);
        height: min(9vh, 17.5vw);
    }

    .spec .lines-holder {
        position: relative;
        left: 0;
        width: 100%;
    }
}