.about-text {
    font-size: max(3vmin, 14px);
    display: inline;
}

.about-holder {
    padding: 1vh 5vw;
    
}

.about-underline{
    text-decoration: underline var(--main-color) 2px;
}