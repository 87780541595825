.cert-holder {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 0 5vw;
    justify-tracks: center;
}

.cert {
    margin: 0 auto;
    width: 18.5vw;
    height: 26.936vw;
    object-fit: cover;
    transition: transform .5s, background .5s;
    cursor: pointer;
    transform: none;
}

.cert:hover {
    transform: scale(1.15)
}

.cert:active::before {
    width: 18.5vw;
}



.cert:active:hover {
    transform: none;
}

.cert-full {
    object-fit: contain;
    height: 100vh;
    width: 100vw;
}

.cert-backdrop {
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1100;
    position: fixed;
    background-color: #0000007f;
    top: 0;
}

@media (orientation: landscape) {
    .cert-backdrop {
        top: 0vh;
        height: 100%;
    }
}



.cert-enter{
    opacity: 0;
}
.cert-enter-active{
    opacity: 1;
    transition: all .5s;
}

.cert-enter .cert-full{
    scale: 0;
}
.cert-enter-active .cert-full{
    scale: 1;
    transition: all .5s;
}

.cert-exit{
    opacity: 1;
}
.cert-exit-active{
    opacity: 0;
    transition: all .5s;
}

.cert-exit .cert-full{
    scale: 1;
}
.cert-exit-active .cert-full{
    scale: 5;
    transition: all .5s;
}