.s-anchor {
    margin-top: -5vh;
    height: 5vh;
}

.s-title {
    flex-shrink: 0;
    font-size: max(18px, 6vmin);
    text-align: center;
}

.title-flex {
    padding-top: 5vh;
    display: flex;
    align-items: center;
    gap: 2.5vw;
    padding-left: 5vw;
    padding-right: 5vw;
    padding-bottom: 3vh;
}


.title-flex>hr {
    border-width: 3px;
}



@media (orientation:landscape) {
    .s-anchor.pr,
    .title-flex.pr {
        display: none;
    }

    .inverse-d {
        flex-direction: row-reverse;
    }

    .title-flex>hr:first-child {
        width: 25%;
    }
}

@media (orientation:portrait) {
    .title-flex {
        flex-direction: column;
    }
}