.image-button {
    background-color: transparent;
    border-color: var(--main-color);
    border-style: solid;
    border-radius: 50%;
    border-width: 1px;
    color: var(--main-color);
    width: 5vh;
    height: 5vh;
    padding: 0;
}

.image-button>.bi {
    margin: auto;
    font-size: 2.8vh;
}

.image-button-opaque {
    background-color: #00000033;
    box-shadow: 0 4px 4px #00000040;
}

.image-button-dark{
    background-color: #0000007f;
    box-shadow: 0 4px 4px #00000080;
}

.image-button-small {
    width: 3.5vh;
    height: 3.5vh;
}

.image-button-small>.bi {
    margin: auto;
    font-size: 1.96vh;
}

@media (orientation: landscape) {
    .image-button {
        width: 10vh;
        height: 10vh;
    }

    .image-button-small {
        width: 7vh;
        height: 7vh;
    }

    .image-button>.bi {
        font-size: 5.5vh;
    }

    .image-button-small>.bi {
        margin: auto;
        font-size: 3.85vh;
    }

    .image-button>.bi-chevron-left {
        padding-right: 1vh;
    }

    .image-button>.bi-chevron-right {
        padding-left: 1vh;
    }
}